import React, {useState} from "react";
import styled from 'styled-components';
import { FaRegHandPointRight } from "react-icons/fa6";
import ReactHtmlParser from 'react-html-parser';
import { Section, WrapperBlog } from "../DesignSystem/SelfTissus/ContainersST";
import { Card } from 'antd';
import picDefault from "../../assets/images/photo.jpg";
import BoutonNoirLnkST from "../DesignSystem/SelfTissus/BoutonNoirLnk";

const Img = styled.img`
max-height: 120px;
box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;

`;

const Wrap = styled.div`
width: 100%;
display: flex;
align-items: center;
gap: 20px;
justify-content: flex-start;

`;

const Txt = styled.a`
color: ${props => props.theme.grey06ST};
`;

const Wrapper = styled.div`
width: 100%;
display: flex;
flex-direction: column;
align-content: center;
align-items: center;
justify-content: center;
gap: 30px;
`;

const Title = styled.p`
font-size: 20px;
font-weight: 700;
text-align: center;
margin-bottom: 30px;
`;



const data = {
    Titre: "Titre",
    Corps: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>",
}

export default function ArticleBlogsST({titre}) {

  return (
      <Section className="">
         <hr></hr>
        <Title className="left mb30">{'Blogs liés à ce produit : '}</Title>
         <Wrapper>
            <Wrap>
                <Img src={"https://www.selftissus.fr/ysi/WebData_d5776de4-2de5-4aa7-8559-e25323e9f0da.webp"}
                onError={(e) => {
                e.target.onerror = null;
                e.target.src = picDefault;
                }} loading="lazy"
                    />
                <Txt>Guide pour choisir le thermocollant</Txt>
            </Wrap>
            <Wrap>
                <Img src={"https://www.selftissus.fr/ysi/WebData_5340d791-dec4-4d8c-942a-4a0360fc236a.webp"}
                onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = picDefault;
                    }} loading="lazy"
                    />
                <Txt>Les métiers autour de la couture</Txt>
                    
            </Wrap>
            
        </Wrapper>
      </Section>
  );
}