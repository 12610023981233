import React, {useState} from "react";
import styled from 'styled-components';
import { FaRegHandPointRight } from "react-icons/fa6";
import ReactHtmlParser from 'react-html-parser';
import { Section, WrapperBlog } from "../DesignSystem/SelfTissus/ContainersST";
import { Card } from 'antd';
import picDefault from "../../assets/images/photo.jpg";
import BoutonNoirLnkST from "../DesignSystem/SelfTissus/BoutonNoirLnk";

const Img = styled.img`
max-height: 120px;
box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;

`;

const Wrap = styled.div`
width: 100%;
display: flex;
align-items: center;
gap: 20px;
justify-content: flex-start;

`;

const Txt = styled.a`
color: ${props => props.theme.grey06ST};
`;

const Wrapper = styled.div`
width: 100%;
display: flex;
flex-direction: column;
align-content: center;
align-items: center;
justify-content: center;
gap: 30px;
`;

const Title = styled.p`
font-size: 20px;
font-weight: 700;
text-align: center;
margin-bottom: 30px;
`;



const data = {
    Titre: "Titre",
    Corps: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>",
}

export default function BlogProduitsST({titre}) {

  return (
      <Section className="">
         <hr></hr>
        <Title className="left mb30">{'Produits liés à ce blog : '}</Title>
         <Wrapper>
            <Wrap>
                <Img src={"https://www.selftissus.fr/ysi/Article_6c151297-533c-4c2c-87cb-b79e98079151.webp"}
                onError={(e) => {
                e.target.onerror = null;
                e.target.src = picDefault;
                }} loading="lazy"
                    />
                <Txt>Tissu jersey Oeko-Tex imprimé Marine Bleu marine </Txt>
            </Wrap>
            <Wrap>
                <Img src={"https://www.selftissus.fr/ysi/Article_b1ad1192-95e5-4a9b-852b-3b0f46993c30.webp"}
                onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = picDefault;
                    }} loading="lazy"
                    />
                <Txt>Tissu jersey Oeko-Tex imprimé Marine Bleu marine </Txt>
                    
            </Wrap>
            
        </Wrapper>
      </Section>
  );
}