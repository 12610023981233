import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import ReactHtmlParser from 'react-html-parser';
import { useSelector } from "react-redux";
import { Card } from 'antd';
import LienNoirST from "../DesignSystem/SelfTissus/LienNoir";
import { InputStyled, FormGroupStyled, LabelStyled } from "../DesignSystem/SelfTissus/Forms";
import BoutonST from "../DesignSystem/SelfTissus/Bouton";
import picDefault from "../../assets/images/photo.jpg";
import  {FormGroup ,Label} from "reactstrap";
import SpinnerST from "../DesignSystem/SpinnersST/Spinner";
import PopErrorST from "../PopupsST/PopError";
import { FaTrashAlt,FaPlus } from "react-icons/fa";

const CardGridStyled = styled(Card.Grid)`
&.nom {
    width: 60%;
    &.mob {
       width: 100%; 
    }
    @media only screen and (max-width: 786px) {
        width: 100%;
    }
}

&.quantite{
    width: 25%;
    &.mob {
       width: 60%; 
    }
    @media only screen and (max-width: 786px) {
        width: 60%;
    }
}
&.prix{
    width: 15%;
    &.mob {
       width: 40%; 
    }
    @media only screen and (max-width: 786px) {
        width: 40%;
    }
}
`;

const Message = styled.div`
background: ${props => props.theme.orangeST};
padding: 20px;
margin: 35px 0;
border-radius: 5px;
box-shadow: 0 0 10px 0 rgba(0,0,0,0.4);
&>p {
  margin: 0;
}
`;

const PanierWrap = styled.div`
width: 100%;
&>.ant-card {
   background: #ffffff75;
   padding: 20px;
}
&>.ant-card > div.ant-card-body > .ant-card-grid {
    display: flex;
    align-items: center;
}
&.mob {
    &>.ant-card > div.ant-card-body > .ant-card-grid.pc {
    display: none;
}
}
&>.ant-card > div.ant-card-body > .ant-card-grid.pc {
    @media only screen and (max-width: 786px) {
        display: none;
    }
}
`;

const Message2 = styled.span`
position: absolute;
bottom: -22px;
left: -15px;
color: ${(props) => props.theme.orangeST};
font-size: 12px; 
min-width: 250px;
`;

const Produit = styled.div`
display: flex;
align-items: center;
flex-wrap: wrap;
gap: 30px;`
const Img = styled.img`
width: 100px;
height: 100px;
box-shadow: 0 0 10px 0 rgba(0,0,0,0.4);
`
const Txt = styled.div`
display: flex;
flex-direction: column;
align-content: flex-start;
gap: 20px;
flex-wrap: wrap;
&>span {
    font-size: 20px; 
    color: ${props => props.theme.grey02ST};
    @media only screen and (max-width: 786px) {
        padding: 10px;
    }
}
`;

const Prix = styled.div`
color: ${props => props.theme.grey02ST};
text-align: right;
width: 100%;
@media only screen and (max-width: 786px) {
    text-align: center;
}
`

const Quantity = styled.div`
width: 100%;
display: flex;
align-items: center;
gap: 20px;
justify-content: space-between;
&>.trash {
    font-size: 20px;
    color: ${props => props.theme.grey02ST};
    &:hover {
        color: ${props => props.theme.redST};
        cursor: pointer;
    }
}
` 

const Promo = styled.div`
display: flex;
align-items: center;
gap: 20px;
justify-content: flex-end;
width: 100%;
margin-top: 20px;
&.flexCol {
  flex-direction: column;
  gap: 10px; 
  justify-content: flex-end;
  &> div, p {
    align-self: end;
  }
}
`

const Livr = styled.div`
width: auto;
border: 2px solid #f0f0f0;
position: relative;
display: flex;
gap: 5px;
padding: 10px 20px;
margin-top: 40px;
justify-content: flex-end;
margin-left: auto;
`

const Total = styled.div`
padding: 20px 0;
width: 100%;
display: flex;
flex-direction: column;
align-items: flex-end;
gap: 20px;
&>div,a {
    width: 40%;
    @media only screen and (max-width: 786px) {
        width: 100%;
    }
}
&.mob {
    &>div,a {
        width: 100%;
    }
}
`

const Btn = styled.button`
min-width: 200px;
display: flex;
gap: 10px;
align-items: center;
background: none;
border: 1px solid ${props => props.theme.grey02ST};
cursor: pointer;
color: ${props => props.theme.grey02ST};
font-size: 14px;
align-self: end;
&:hover {
    color: ${props => props.theme.orangeST};
    border-color: ${props => props.theme.orangeST};
}
&>svg {
    font-size: 12px;
}
`;
const BtnT = styled.button`
position: absolute;
left: -35px;
top: 10px;
background: none;
border: none;
cursor: pointer;
color: ${props => props.theme.grey02ST};
font-size: 20px;
align-self: end;
&:hover {
    color: ${props => props.theme.orangeST};
}
`;



export default function PanierRecapST({className, atelierOnly, isTermsAccepted, handleCheckboxChange, onApplyPromo, onApplyCarteCadeau, onApplyPoints}) {
const basket = useSelector(state => state.basket);
const relais = useSelector(state => state.relais?.relais);
const mag = useSelector(state => state.magasin?.magasin);
const date = useSelector(state => state.date?.date);
const heure = useSelector(state => state.heure?.heure);
const adresse = useSelector(state => state.adresse?.adresse);
const modeL = useSelector(state => state.modeLivr?.modeLivraison);
const [quantities, setQuantities] = useState(basket?.items?.map(item => item.quantity));
const [isLoading, setIsLoading] = useState(false);
const [txt, setTxt] = useState("");
const user = useSelector(state => state.user?.user?.Contact); 
const [promoCode, setPromoCode] = useState('');
const [codeCarteCadeau, setCodeCarteCadeau] = useState('');
const [isErrorVisible, setIsErrorVisible] = useState(false);
const [carteCadeauCodes, setCarteCadeauCodes] = useState(['']);
const [points, setPoints] = useState(0);
const [euros, setEuros] = useState(0);
const [msgError, setMsgError] = useState("");

function formatDate(dateString) {
    const [year, month, day] = dateString?.split('-');
    return `${day}/${month}/${year}`;
  }

  const handleInputChangeCodePromo = (e) => {
    const newPromoCode = e.target.value;
    setPromoCode(newPromoCode);
    onApplyPromo(newPromoCode);
  };
  

  const handleInputChangeCarteCadeau2 = (index, value) => {
    const newCodes = [...carteCadeauCodes];
    newCodes[index] = value;
    setCarteCadeauCodes(newCodes);
    onApplyCarteCadeau(newCodes);
  };

  const handleAddCarteCadeau2 = () => {
    setCarteCadeauCodes([...carteCadeauCodes, '']);
  };

  const handleRemoveCarteCadeau2 = (index) => {
    const newCodes = carteCadeauCodes.filter((_, i) => i !== index);
    setCarteCadeauCodes(newCodes);
    console.log("newCodes",newCodes)
  };
  const handleInputChangePoints = (e) => {
    const value = Number(e.target.value);
    console.log("valPoints",value)
    const step = user?.ValeurPointsMultiple || 1.2;
    let maxPoints = user?.ValeurPointsTotal || 0;
    //pour éviter les erreurs de calculs (point flottant) JS il faut *10, sinon les résultas sont faux
    const isInteger = Number.isInteger((value*10 / step*10))

   if (!isInteger) {
     setMsgError(`La valeur doit être un multiple de ${step}`);
   } else {
        setMsgError("");
    }
    if (value <= maxPoints) {
      setPoints(value);
      onApplyPoints(value);
    } else {
      setPoints(maxPoints);
      onApplyPoints(maxPoints);
    }
  };

const totalPrice = basket?.items?.reduce((total, item, index) => {
    const price = item?.newPrice ?? item.Prix ?? item?.PUTTC;
        return total + (quantities[index] * price);
    }, 0).toFixed(2);

    return (
            <PanierWrap className={className}>
                <h2 className="center homemadeTitle initial green">Récapitulatif de votre commande</h2>
                <Card bordered={false}>
                <Card.Grid className="pc" hoverable={false} style={{width:"60%"}}>
                <p className="mb0 uppercase">Produit</p>
                </Card.Grid>

                <Card.Grid className="pc"  hoverable={false} style={{width:"25%"}}>
                <p className="mb0 uppercase">Quantité</p>
                </Card.Grid>

                <Card.Grid className="pc" hoverable={false} style={{width:"15%",textAlign:"right"}}>
                <p className="mb0 uppercase right">Total</p>
                </Card.Grid>

                {
                    basket.items.map((item, i) => (
                        <>
                            {item?.mode === "article" ? (
                                <>
                                    <CardGridStyled className={`nom ${className}`}>
                                        <Produit>
                                            <Img src={item?.Photos[0]?.image?.url ? item?.Photos[0]?.image?.url : picDefault} alt={item?.Photos[0]?.image?.alt || "image du produit"} onError={(e) => {
                                                    e.target.onerror = null;
                                                    e.target.src = picDefault;
                                            }}/>
                                            <Txt>
                                                <LienNoirST lib={ReactHtmlParser(item?.libArticle)} href={item?.slug || "#"}  margin={"0"}/>
                                                <span>€{item?.newPrice ? item?.newPrice : item?.Prix}</span>
                                            </Txt>
                                        </Produit>
                                    </CardGridStyled>

                                    <CardGridStyled className={`quantite ${className}`} hoverable={false}>
                                        <Quantity>
                                            <FormGroupStyled>
                                                <InputStyled
                                                    id={`quantity-${i}`}
                                                    name="number"
                                                    type="number"
                                                    value={quantities[i]}
                                                    readonly
                                                    disabled
                                                />
                                            </FormGroupStyled>
                                        </Quantity>
                                    </CardGridStyled>

                                    <CardGridStyled className={`prix ${className}`} hoverable={false}>
                                        <Prix className="fs20">€{(quantities[i] * (item?.newPrice ? item?.newPrice : item?.Prix)).toFixed(2)}</Prix>
                                    </CardGridStyled>
                                </>
                            ) : item?.mode === "atelier" ? (
                                <>
                                <CardGridStyled className={`nom ${className}`}>
                                    <Produit>
                                        <Img src={item?.img?.url ? item?.img?.url : picDefault} alt={item?.img?.alt || "image de l'atelier"} onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src = picDefault;
                                            }}/>
                                        <Txt>
                                            <LienNoirST lib={item?.lib} href={item?.slug} margin={"0"}/>
                                            <span>€{item?.PUTTC}</span>
                                        </Txt>
                                    </Produit>
                                </CardGridStyled>

                                <CardGridStyled className={`quantite ${className}`} hoverable={false}>
                                    <Quantity>
                                        <FormGroupStyled>
                                            <InputStyled
                                                id={`quantity-${i}`}
                                                name="number"
                                                type="number"
                                                value={quantities[i]}
                                                readonly
                                                disabled
                                            />
                                        </FormGroupStyled>
                                    </Quantity>
                                </CardGridStyled>

                                <CardGridStyled className={`prix ${className}`} hoverable={false}>
                                    <Prix className="fs20">€{(quantities[i] * (item?.PUTTC)).toFixed(2)}</Prix>
                                </CardGridStyled>
                                </>
                            ) : null}
                        </>
                    ))
                }
                
                <Promo>
                    {isErrorVisible && <PopErrorST message={txt} titre={"CODE"} onClick={() => { setIsErrorVisible(false);}} />}
                <FormGroupStyled floating>
                    <InputStyled
                        className="min"
                        id="codePromo"
                        name="codePromo"
                        placeholder="Saissir un code"
                        type="text"
                        value={promoCode}
                        onChange={handleInputChangeCodePromo}
                    />
                    <LabelStyled for="exampleNumber">Code promo ?</LabelStyled>
                    </FormGroupStyled>
                </Promo>
                
                <Promo className='flexCol'>
                {carteCadeauCodes.map((code, index) => (
                    <FormGroupStyled floating key={index}>
                    <InputStyled floating
                        className="min"
                        id={`carteCadeau-${index}`}
                        name={`carteCadeau-${index}`}
                        placeholder="Saisir un code"
                        type="text"
                        value={code}
                        onChange={(e) => handleInputChangeCarteCadeau2(index, e.target.value)}
                    />
                    <LabelStyled for={`carteCadeau-${index}`}>Carte cadeau ?</LabelStyled>
                    {carteCadeauCodes.length > 1 && index > 0 && (
                        <BtnT type="button" onClick={() => handleRemoveCarteCadeau2(index)}>
                        <FaTrashAlt/>
                        </BtnT>
                    )}
                    </FormGroupStyled>
                ))}
                <Btn type="button" onClick={handleAddCarteCadeau2}>
                    <FaPlus/>Ajouter une carte cadeau
                </Btn>
                </Promo>
                {/* {user?.ValeurPointsTotal > 0 &&
                <Promo className='flexCol'>
                    <p className='fs14 green m0 right'>Votre solde de fidélité :<br></br> <b>{user?.ValeurPointsTotal}€</b></p> 
                    <div>
                    <FormGroupStyled floating>
                    <InputStyled floating
                        className="min"
                        id="points"
                        name="points"
                        type="number"
                        min="0"
                        max={user?.ValeurPointsTotal}
                        step={user?.ValeurPointsMultiple}
                        value={points}
                        onChange={handleInputChangePoints}
                    />
                    <LabelStyled for="points">Utiliser ?</LabelStyled>
                    <Message2>{msgError}</Message2>
                    </FormGroupStyled>
                    </div>
                </Promo>} */}
             
                {!atelierOnly &&<Livr>
                    <div><p className='green bold fs20'>LIVRAISON CHOISIE</p>
                    {modeL === 2 ? <><p className='bold'>Click & Collect</p><p className='green'>{mag?.Nom}</p><p className=''>Le {formatDate(date?.date)} à {heure?.heure}</p></>
                    : modeL === 3 ? <><p className='bold'>Point Relais</p><p className='green'>{relais?.Nom}</p></>
                    : modeL === 1 ? <><p className='bold'>Livraison à domicile</p></>
                    : <span>Veuillez choisir un mode de livraison</span>}


                    </div>
                </Livr>}
                
                <Total className={className}>
                    <Prix className="fs20">Total : &nbsp;<span> {totalPrice} EUR</span></Prix>
                    <Prix className="fs16">Taxe incluse, frais d’expédition et réductions calculés à l’étape du paiement</Prix>
                </Total>
                <div>
                <FormGroup check>
                    <InputStyled type="checkbox" 
                    checked={isTermsAccepted} onChange={handleCheckboxChange}
                    />
                    <Label check>
                    J'accepte les <a href="/informations-legales/cgv" target='_blank'>conditions générales de vente</a>
                    </Label>
                </FormGroup>
                {!isTermsAccepted &&<Message>
                    <p className='white bold'>Vous devez accepter les conditions générales de vente pour continuer</p>
                </Message>}
                </div>

            </Card>

            
               
            </PanierWrap>
  );
}
